import Search from './components/Search';
import Marketing from './components/Marketing';
import Ads from './components/Ads';
import Specialty from './components/Specialty';
import Divider from '../common/components/Divider';
import DoctorInformation from './components/DoctorInformation';
import useAds from '../api/hooks/useAds';
import Calendar from './components/Calendar';
import { useEffect, useRef, useState } from 'react';
import PendingAppointments from '../doctorAppointmentManager/components/pendingAppointments/PendingAppointments';
// import AppointmentCalendar from '../components/appointmentCalendar/AppointmentCalendar';
import Axios from '../v2/services/Axios';
import { Carousel } from 'react-responsive-carousel';
import imgShapetwo from '../images/shape-2.png';
import imgShapethree from '../images/shape-3.png';
import sliderDoc from '../images/slider-doc.png';
import AppointmentPending from '../v2/pages/appointment-detail/doctor/AppointmentPending';
export const DoctorProfile = {
  getME: () => Axios.get('/doctor-data/whoami')
};
export default function Home(props) {
  const { user, authenticatedUser } = props;
  const { adsToDisplay, onClickAd } = useAds();
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const isDoctor = !!user && user.usertype === 2;
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //     if (isDoctor) getPendingAppointment();
  //   }, []);

  //   const getPendingAppointment = async () => {
  //     let getHistory = await Axios.get('/appointment-doctor/history');
  //     if (getHistory && getHistory.pending) {
  //       setPendingAppointments(getHistory.pending);
  //       console.log(getHistory.pending);
  //     }
  //   };
  return (
    <>
      {/*Search*/}
      <div className="pt-12 lg:px-8 mx-auto px-4 relative sm:px-6">
        <h2 className="docter-link-title text-center">
          <b>Los especialistas médicos</b> reunidos en una sola plataforma
        </h2>
      </div>
      {!isDoctor ? <Search /> : <AppointmentPending isHomeRoute={true} />}

      {!isDoctor ? (
        <>
          <Marketing />
          <Ads adsToDisplay={adsToDisplay} onClickAd={onClickAd} />
          <div className="bg-dl-screen-lightblue pb-12 pt-20">
            <Specialty />
            <Divider />
          </div>
        </>
      ) : (
        <div className="slider-main py-20">
          <div className="wrapper-box">
            <div className="slider-inner">
              <div className="slider-left">
                <Carousel showStatus={false} showIndicators={false} showThumbs={false} autoPlay infiniteLoop interval={4000}>
                  <div>
                    <blockquote className="slider-content-box h-full	">
                      <h2 className="slider-title">Tu doctor a un solo clic</h2>
                      <ul className="list-disc">
                        <li>Programación en línea</li>
                        <li>Recordatorios de citas</li>
                        <li>Historial médico</li>
                        <li>Perfil personalizado</li>
                        <li>Aceptar/Rechazar citas</li>
                        <li>Acceso al estado de las citas</li>
                      </ul>
                    </blockquote>
                  </div>
                  <div>
                    <blockquote className="slider-content-box">
                      <h2 className="slider-title">tu doctor a un solo click</h2>
                      <ul className="list-disc">
                        <li>Aumenta tu visibilidad y construye tu reputación online</li>
                        <li>Ten el control total de tus citas</li>
                        <li>Aporta un servicio digital y de calidad a tus pacientes</li>
                      </ul>
                    </blockquote>
                  </div>
                </Carousel>
              </div>
              <div className="slider-right">
                <span className="shape-three">
                  <img src={imgShapethree} alt="" className="" />
                </span>
                <figure className="text-center">
                  {' '}
                  <img src={sliderDoc} alt="" className="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*Ads*/}

      {/*Support*/}
      {/* <HelpAndSupportPage /> */}
      {/* Specialty List */}

      {/* Support Home div */}
      <DoctorInformation authenticatedUser={authenticatedUser} />
      {/* <ContactUsForDoctorsElement /> */}
      {/* Apps downloads */}
      {/* Mientras no tengamos las apps subidas a los markets no estara habilitado este componente */}
      {/*<DownloadAppElement />*/}
      {/* Footer */}
    </>
  );
}
