import create from 'zustand';
import AppointmentHistory from '../../../../doctorAppointmentManager/components/appointmentHistory/AppointmentHistory';
import AppointmentPending from './AppointmentPending';
import Navigation from './Navigation';
import BlockSlot from './BlockSlot';

export const useAppointmentNavStore = create((set) => ({
  personal: true,
  info: false,
  tabs: [{ name: 'Citas Pendientes' }, { name: 'Historial' }, { name: 'Bloquear agenda' }],
  selectedTabIndex: 1,
  setSelectedTabIndex: (index) => set({ selectedTabIndex: index })
}));

const AppointmentDoctor = () => {
  const { selectedTabIndex } = useAppointmentNavStore((state) => state);
  return (
    <div className="lg:grid lg:grid-cols-12 gap-4">
      <Navigation />
      {selectedTabIndex === 1 && <AppointmentPending />}
      {selectedTabIndex === 2 && <AppointmentHistory />}
      {selectedTabIndex === 3 && <BlockSlot />}
    </div>
  );
};

export default AppointmentDoctor;
