import React, { useEffect, useState } from 'react';
import imgSlide from './../../images/dl-img-1.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import docterIMG from './../../images/docter-left-img.png';
import shapeOne from './../../images/shape-1.png';
import sliderDoc from './../../images/slider-doc.png';
import mediBottal from './../../images/medi-bottal.png';
import imgShapetwo from './../../images/shape-2.png';
import imgShapethree from './../../images/shape-3.png';
import Configurations from '../../common/utils/configurations';
import { Link } from "react-router-dom";
import axios from 'axios';
import { handleImageError } from '../../common/helpers/helper';
const APP_SERVER = process.env.REACT_APP_API_SERVER;
function Marketing() {
   const [recommendedDoctorsData, setRecommendedDoctorsData] = useState([]);
   const API_BASE_URL = process.env.REACT_APP_API_SERVER;

   const getData = async () => {
      await axios.get(`${API_BASE_URL}/search-doctors/get-recommended-doctors`)
         .then((res) => {
            setRecommendedDoctorsData(res?.data);
         })
         .catch((error) => {
            console.log(error);
         });
   }
   useEffect(() => {
      getData();
   }, [])





   return (
      <div className="bg-dl-screen-lightblue ">
         <div className='docter-just-click-main'>
            <div className='wrapper-box'>
               <div className='docter-just-click'>
                  <figcaption>
                     <h3>Tu doctor a un solo clic</h3>
                  </figcaption>
                  <figure>
                     <img
                        className=""
                        src={docterIMG}
                        alt=""
                     />
                  </figure>
               </div>
            </div>
            <span className='shape-one'>
               <img
                  className=""
                  src={shapeOne}
                  alt=""
               />
            </span>
         </div>
         {/*featured-physicians*/}
         <div className='featured-physicians-main pt-8 pb-12'>
            <span class="shape-two">
               <img
                  src={imgShapetwo}
                  alt=""
                  className=""
               />
            </span>
            <div className='wrapper-box'>
               <h2 className='common-title pb-5'>Médicos destacados</h2>
               <div className='grid grid-cols-4 gap-8'>
                  {/* <div className='featured-physicians-inner'>
                     <figure>
                        <span></span>
                     </figure>
                     <figcaption>
                        <h3>Nombre del médico</h3>
                        <p className='docter-info'>Oftalmología</p>
                        <button className='btn-primary  m-auto'>Conocer más</button>
                        <a className='bottom-link '>Santa Tecla</a>
                     </figcaption>
                  </div> */}
                  {
                     recommendedDoctorsData?.map((item, index) => {
                        return (
                           <div className='featured-physicians-inner' key={index}>
                              <figure className="border-4 border-sky-400	rounded-full w-20 h-20">
                                 <img className="h-full w-full rounded-full " src={item?.personalImage ? `${APP_SERVER}/${item?.personalImage}` : Configurations?.defaultImage} alt="" onError={handleImageError} />
                              </figure>
                              <figcaption>
                                 <h3 className='h-12'>{item.fullName}</h3>
                                 <p className='docter-info'>{item.speciality}</p>
                                 <Link to={`/doctor-results/${item.id}/${item.fullName.replace(' ', '-')}`}>
                                    <button className='btn-primary btn-md m-auto'>Conocer más</button>
                                 </Link>
                                 <a className='bottom-link '>{item.city}</a>
                              </figcaption>
                           </div>
                        )
                     })
                  }
               </div>
            </div>
         </div>
         {/*slider-sec*/}
         <div class="relative divider-add"><div class="absolute inset-0 flex items-center" aria-hidden="true"><div class="w-full border-t border-gray-300 border-line"></div></div><div class="relative flex justify-center "><span class="bg-dl-accent px-2 py-2 text-white rounded-full add-btn"><svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd"></path></svg></span></div></div>
         <div className='slider-main pt-20'>
            <div className="wrapper-box">
               <div className="slider-inner">
                  <div className='slider-left'>
                     <Carousel showStatus={false} showIndicators={false} showThumbs={false} autoPlay infiniteLoop interval={4000}>
                        <div>
                           <blockquote className="slider-content-box h-full	">
                              <h2 className="slider-title">
                                 Tu doctor a un solo clic
                              </h2>
                              <ul className="list-disc">
                                 <li>Programación en línea</li>
                                 <li>Recordatorios de citas</li>
                                 <li>Historial médico</li>
                                 <li>Perfil personalizado</li>
                                 <li>Aceptar/Rechazar citas</li>
                                 <li>Acceso al estado de las citas</li>
                              </ul>
                           </blockquote>
                        </div>
                        <div>
                           <blockquote className="slider-content-box">
                              {/* <h2 className="slider-title">
                                 tu doctor más cerca de ti / tu doctor a un solo click
                              </h2> */}

                              <h2 className="slider-title">tu doctor a un solo click</h2>
                              <ul className="list-disc">
                                 <li>Aumenta tu visibilidad y construye tu reputación online</li>
                                 <li>Ten el control total de tus citas</li>
                                 <li>Aporta un servicio digital y de calidad a tus pacientes</li>
                              </ul>
                           </blockquote>
                        </div>
                     </Carousel>
                  </div>
                  <div className="slider-right">
                     <span class="shape-three">
                        <img
                           src={imgShapethree}
                           alt=""
                           className=""
                        />
                     </span>
                     <figure className='text-center'> <img
                        src={sliderDoc}
                        alt=""
                        className=""
                     /></figure>
                  </div>
               </div>
            </div>
         </div>
         {/*our-plate-forms*/}
         <div className='our-plate-forms pt-20'>
            <div className="wrapper-box">
               <div className='our-plates-form-inner '>
                  <span className='medi-bottal'>
                     <img
                        className=""
                        src={mediBottal}
                        alt=""
                     />
                  </span>
                  <div className='grid grid-cols-2 h-full'>
                     <div className='our-plate-forms-L'>
                        <figcaption>
                           <h3>
                              Se parte de
                              nuestra
                              plataforma
                           </h3>
                        </figcaption>
                     </div>
                     <div className='our-plate-forms-R flex'>
                        <a href='/contact-us' className='btn-primary btn-md btn-sm light-bg m-auto'>Anúnciate aquí</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default React.memo(Marketing)